// // src/components/routes/LandingPage/Footer/Footer.js
import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    return(
        <div>
            <section id="footer-sect" className='footer-container'>

                <section className="contact" >
                    <div className="headi">CONTACT US</div>
                </section>

                <div className='footer-content-first footer-container'>
                    <Link to="/" className="navbar-brand" onClick={scrollToTop}>
                        <img 
                            src="./uploads/com-logooo.ico" 
                            className="footer-logo" 
                            alt="brand" 
                        />
                    </Link>
                    
                    <div className='social-links'>
                        <Link to="https://www.linkedin.com/in/communiaide-communiaide-309aa5311/" rel="noopener noreferrer" onClick={scrollToTop}>
                            <img src="./uploads/linkedin.svg" alt="LinkedIn" className="social-icon" />
                        </Link>
                        
                        <Link to="/" rel="noopener noreferrer" onClick={scrollToTop}>
                            <img src="./uploads/facebook.svg" alt="Facebook" className="social-icon" />
                        </Link>

                        <Link to="https://www.youtube.com/channel/UCaNeHzKKCkhbpPX8Ibq6wuA#" rel="noopener noreferrer" onClick={scrollToTop}>
                            <img src="./uploads/youtube.svg" alt="Youtube" className="social-icon" />
                        </Link>

                        <Link to="https://www.instagram.com/vibhuutyagi/?utm_source=ig_web_button_share_sheet" rel="noopener noreferrer" onClick={scrollToTop}>
                            <img src="./uploads/instagram.svg" alt="Instagram" className="social-icon" />
                        </Link>
                    </div>
                </div>

                <div className='footer-content-second ft-sec'>
                    
                    <div className='deets'>
                        <a href='tel:+91 9899-967-927' className='cont-links'>+91 9899967927</a>
                    </div>
                    <div className='deets'>
                        <a href='mailto:hellan.dheeraj@gmail.com' className='cont-links'>hellan.dheeraj@gmail.com</a>
                    </div>
                    <Link to="/terms" className='footer-links deets' onClick={scrollToTop}>
                        Terms & Conditions
                    </Link>

                </div>

                <div className='footer-content-third'>
                    {/* <div className='deets'>
                        <a 
                            href="https://www.google.com/maps/place/28%C2%B027'26.6%22N+77%C2%B004'47.8%22E/@28.4573969,77.0773717,17z/data=!3m1!4b1!4m4!3m3!8m2!3d28.4573969!4d77.0799466?entry=ttu" 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            className='cont-links'
                        >
                            165 Block C, Sushant Lok Phase I, Sector 43, Gurugram, Haryana 122002
                        </a>
                    </div> */}

                    {/* Google Maps Embed */}
                    <div className="map-container">
                        <iframe
                            title="Google Maps Location"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7000.89583600057!2d77.0773717!3d28.4573969!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d19489923bdb1%3A0x8b6a153bcbbf6b3!2s165%2C%20Block%20C%2C%20Sushant%20Lok%20Phase%20I%2C%20Sector%2043%2C%20Gurugram%2C%20Haryana%20122002!5e0!3m2!1sen!2sin!4v1694298090102!5m2!1sen!2sin"
                            width="90%"
                            height="250"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                </div>
                
            </section>
        </div>
    )
}

export default Footer;
