// src/components/routes/Landing/First/First.js

import React, { useEffect, useRef } from "react";
import './First.css';
import Work from '../Work/Work';
import Team from "../Team/Team";
import Videos from "../Videos/Videos";
import SliderContainer from '../Slider/SliderContainer';
import { Carousel, initMDB } from "mdb-ui-kit";
import CountUp from "react-countup";

const First = () => {
    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        } else {
            console.warn(`Element with id ${id} not found.`);
        }
    };

    // Refs for sections
    const aboutSectionRef = useRef(null);
    const superContainerRef = useRef(null);
    const networkRef = useRef(null); 

    const sections = [aboutSectionRef, superContainerRef, networkRef];

    useEffect(() => {
        // Initialize MDB carousel
        initMDB({ Carousel });

        // Function to handle animations when elements are in view
        const handleIntersection = (entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('in-view');
                } else {
                    entry.target.classList.remove('in-view');
                }
            });
        };

        // Create the observer for elements to animate on scroll
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1 // Trigger when 10% of the element is visible
        };

        const observer = new IntersectionObserver(handleIntersection, observerOptions);

        // Observe all sections for animation
        sections.forEach(section => {
            if (section.current) {
                observer.observe(section.current);
            }
        });

        // Clean up observer when component unmounts
        return () => {
            sections.forEach(section => {
                if (section.current) {
                    observer.unobserve(section.current);
                }
            });
        };
    }, []);

    return (
        <div>
            {/* **********SUPER SECTION************* */}
            <section className='super-container' ref={superContainerRef}>
                <div className="video-background">
                    <video autoPlay loop muted playsInline  
                        className="super-video">
                        <source src="./uploads/super-cover.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                                
                <div className='super-container-content'>
                    <h1 className='super-container-line1 animated-left'>
                        REIMAGINE YOUR BRAND'S FUTURE
                    </h1>
                    <p className='super-container-line2 animated-right'>
                        Transforming Ideas into Impactful Experiences with Innovative Storytelling and Strategic Creativity.
                    </p>
                                
                    <div className='super-container-btns'>
                        <button 
                            type="button" 
                            className="btn btn-info explore_btn" 
                            onClick={() => scrollToSection('categories-container')}
                        >
                            EXPLORE WORK
                        </button>

                        <button 
                            type="button" 
                            className="btn btn-light contact_btn"
                            onClick={() => scrollToSection('footer-sect')}
                        >
                            CONTACT US
                        </button>
                    </div>

                    <div className='super-container-satisfied'>
                        500+ Satisfied Clients
                    </div>
                </div>
            </section>

            {/* Spacer to ensure content below is visible and can be scrolled to */}
            <div style={{ height: '100vh' }}></div>

            {/* **********WHO WE ARE************* */}
            <section className='about-container' ref={aboutSectionRef}>
                <div className='about-content'>
                    <div className='headings'>
                        <div className='heading-1 headi'>A LITTLE BIT</div>
                        <div className='heading-2'>About Us</div>
                    </div>
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-20">
                            <div className="card-img-about">
                                <div className='card-img-left'>
                                    <div className="card border-0 about-cards">
                                        <div className="card-body">
                                            <p className="card-text about-card animated-bottom">
                                                At Communiaide, we ignite growth and transformation for brands, merging language and visuals seamlessly. With a holistic approach and strategic risks, we dive deep into consumer insights, crafting bespoke strategies that elevate brands to new heights. Specializing in brand strategies, digital marketing, and content creation, we streamline processes for maximum efficiency. Trust us to captivate audiences across all media formats, from traditional to digital platforms.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='card-img-right animated-right'>
                                    <img 
                                        src="./uploads/comm-two.webp" 
                                        className="about-bg" 
                                        alt="about-bg" 
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* **********CATEGORIES************* */}
            <Work />

            {/* ******NETWORK**** */}

            <section className="network-container" ref={networkRef} data-os="fade-right">
                <div className="headings">
                    <div className="heading-1 headi">OUR NETWORK</div>
                </div>
                <div className="network-content">
                    <div className="network-blocks">
                        <h1 className="network-block-h1">
                            <CountUp end={6} duration={3} />+
                        </h1>
                        <h6 className="network-block-h2">YEARS IN BUSINESS</h6>
                    </div>
                    <div className="network-blocks">
                        <h1 className="network-block-h1">
                            <CountUp end={40} duration={3} />+
                        </h1>
                        <h6 className="network-block-h2">TEAM MEMBERS</h6>
                    </div>
                    <div className="network-blocks">
                        <h1 className="network-block-h1">
                            <CountUp end={200} duration={3} />+
                        </h1>
                        <h6 className="network-block-h2">ADVERTISING AND MARKETING PROJECTS</h6>
                    </div>
                    <div className="network-blocks">
                        <h1 className="network-block-h1">
                            <CountUp end={50} duration={3} />+
                        </h1>
                        <h6 className="network-block-h2">CLIENTS</h6>
                    </div>
                    <div className="network-blocks">
                        <h1 className="network-block-h1">
                            <CountUp end={92} duration={3} />+
                        </h1>
                        <h6 className="network-block-h2">CLIENT RETENTION RATE</h6>
                    </div>
                </div>
            </section>

            {/* *******VIDEOS******* */}
            <Videos />
            
            {/* *******TEAM******** */}
            <Team />

            {/* *************** */}
            <section className='clients-container'>
                <SliderContainer />   
            </section>
            
        </div>
    );
};

export default First;
